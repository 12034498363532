/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.snow.css";
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-expansion-panel-content > .mat-expansion-panel-body {
  padding: 0 !important;
}

.mat-mdc-list-base .mat-mdc-list-item .mat-mdc-list-item-content {
  padding: 0px !important;
}

.mdc-text-field--filled, .mat-mdc-text-field-wrapper:hover, .mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.menu-overflow-hidden {
  overflow: hidden !important;
  max-width: none !important;
}

.form-field-padding .mat-mdc-text-field-wrapper {
  margin-bottom: -0.7em;
}

::ng-deep .cdk-overlay-container {
  z-index: 9999 !important;
}

.outerCover {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
}

.innerCover {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 0.5rem;
}

.widget-title {
  padding: 0.5rem 0;
  box-sizing: border-box;
}

.container-with-thin-scrollbar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(156, 156, 156, 0.3019607843);
  background-color: #ffffff;
  border-radius: 10px;
}

.container-with-thin-scrollbar::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}

.container-with-thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0.44, rgb(220, 218, 218)), color-stop(0.72, rgb(227, 227, 227)), color-stop(0.86, rgb(208, 208, 208)));
}

.selectedNow {
  border: 2px solid rgb(50, 206, 235);
}

.hoveredNow {
  border: 2px solid rgb(173, 231, 253);
}

.invisible {
  visibility: hidden;
}

.mat-headline-5 {
  font: 400 28px/32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 34px;
}

.mat-headline-4 {
  font: 400 34px/40px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-headline-3 {
  font: 400 45px/48px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-headline-2 {
  font: 400 56px/56px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-headline-1 {
  font: 400 112px/112px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}